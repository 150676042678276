import React, { useState, useRef, useEffect } from "react";
import ReactPlayer from "react-player";
import "./video-player.css";

const PopupVideoPlayer = () => {
  const [isOpen, setIsOpen] = useState(false);
  const popupRef = useRef(null);

  const openPopup = () => setIsOpen(true);
  const closePopup = () => setIsOpen(false);

  const handleClickOutside = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      closePopup();
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }
  }, [isOpen]);

  return (
    <div>
      <button onClick={openPopup} className="play-video-button">
        Department Video 🔗
      </button>

      {isOpen && (
        <div className="popup-overlay">
          <div className="popup-content" ref={popupRef}>
            <ReactPlayer
              url="https://youtu.be/6NeUtK95YjU"
              playing
              controls
              width="100%"
              height="100%"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default PopupVideoPlayer;
