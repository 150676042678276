import React, { useEffect, useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import axios from 'axios';
import Loader from '../RioLoad';

const getConfigurableProps = () => ({
  showArrows: true,
  showStatus: false,
  showIndicators: true,
  infiniteLoop: true,
  showThumbs: false,
  useKeyboardArrows: true,
  autoPlay: true,
  stopOnHover: true,
  swipeable: true,
  dynamicHeight: true,
  emulateTouch: true,
  autoFocus: false,
  interval: 2000,
  transitionTime: 500,
  swipeScrollTolerance: 5
});

const CarouselComponent = ({ base64Images }) => {
  if (!base64Images?.length) return null;

  return (
    <div className="carousel-container">
      <Carousel {...getConfigurableProps()}>
        {base64Images.map((image, index) => (
          <div key={index} className="carousel-slide">
            <img
              src={`data:image/jpeg;base64,${image}`}
              alt={`Achievement ${index + 1}`}
            />
          </div>
        ))}
      </Carousel>
    </div>
  );
};

const StudAchi = () => {
  const [data, setData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [visibleCount, setVisibleCount] = useState(6);
  const [loading, setLoading] = useState(true);
  const baseUrl = "https://cse.spit.ac.in/api";

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${baseUrl}/generals/students_achievement`);
        setData(response.data);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleViewMore = () => {
    setVisibleCount(prevCount => prevCount + 6);
  };

  const openImagesWindow = (photos) => {
    const newWindow = window.open('', '_blank');
    if (newWindow) {
      newWindow.document.write(`
        <html>
          <head>
            <title>Achievement Images</title>
            <style>
              body { 
                background: #f5f5f5;
                padding: 2rem;
                margin: 0;
                display: grid;
                grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
                gap: 1rem;
              }
              img {
                width: 100%;
                height: 300px;
                object-fit: cover;
                border-radius: 8px;
                box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
              }
            </style>
          </head>
          <body>
            ${photos.map((photo, index) =>
        `<img src="data:image/jpeg;base64,${photo}" alt="Achievement ${index + 1}" />`
      ).join('')}
          </body>
        </html>
      `);
      newWindow.document.close();
    } else {
      alert('Popup blocked. Please allow popups for this website.');
    }
  };

  const filteredData = data.filter(item => 
    item.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="achievements-container">
      <h1 className="achievements-title">Student Achievements</h1>

      <input
        type="text"
        placeholder="Search by student name..."
        value={searchTerm}
        onChange={handleSearch}
        className="search-bar"
      />

      {loading ? (
        <div className=""><Loader/> </div>
      ) : (
        <div className="achievements-grid">
          {filteredData.slice(0, visibleCount).map((item, index) => (
            <div key={index} className="achievement-card">
              <CarouselComponent base64Images={item.photo} className="photos" />

              <div className="card-content">
                <div className="card-header">
                  <h2 className="student-name">{item.name}</h2>
                  <div className="date">
                    <svg className="icon" viewBox="0 0 24 24">
                      <path fill="currentColor" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                    </svg>
                    {item.date}
                  </div>
                </div>

                <div className="achievement-section">
                  <svg className="icon award-icon" viewBox="0 0 24 24">
                    <path fill="currentColor" d="M12 15.4l-3.76 2.27 1-4.28-3.32-2.88 4.38-.38L12 6.1l1.71 4.04 4.38.38-3.32 2.88 1 4.28z" />
                  </svg>
                  <p className="achievement-title">{item.achievement}</p>
                </div>

                <p className="achievement-details">{item.details}</p>

                <div className="button-group">
                  {item.photo && (
                    <button
                      onClick={() => openImagesWindow(item.photo)}
                      className="button button-outline"
                    >
                      View Gallery
                    </button>
                  )}

                  {item.linkedin_url && (
                    <button
                      onClick={() => window.open(item.linkedin_url, '_blank')}
                      className="button button-filled"
                    >
                      <svg className="icon linkedin-icon" viewBox="0 0 24 24">
                        <path fill="currentColor" d="M19 3a2 2 0 012 2v14a2 2 0 01-2 2H5a2 2 0 01-2-2V5a2 2 0 012-2h14m-.5 15.5v-5.3a3.26 3.26 0 00-3.26-3.26c-.85 0-1.84.52-2.32 1.3v-1.11h-2.79v8.37h2.79v-4.93c0-.77.62-1.4 1.39-1.4a1.4 1.4 0 011.4 1.4v4.93h2.79M6.88 8.56a1.68 1.68 0 001.68-1.68c0-.93-.75-1.69-1.68-1.69a1.69 1.69 0 00-1.69 1.69c0 .93.76 1.68 1.69 1.68m1.39 9.94v-8.37H5.5v8.37h2.77z" />
                      </svg>
                      LinkedIn Profile
                    </button>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      )}

      {visibleCount < filteredData.length && !loading && (
        <div className="view-more-container">
          <button onClick={handleViewMore} className="view-more-button">
            View More
          </button>
        </div>
      )}

      <style jsx>{`
        .achievements-container {
          // width: 90%;
          max-width: 1400px;
          margin: 0 auto;
          padding: 2rem 0;
        }
        

        .carousel .slide img {
        height: fit;
  width: 100%;
  vertical-align: top;
  border: 0;
  object-fit: contain;
}
        .achievements-title {
          font-size: 2.5rem;
          font-weight: bold;
          text-align: center;
          margin-bottom: 3rem;
          color: #333;
          position: relative;
        }

        .achievements-title:after {
          content: '';
          display: block;
          width: 60px;
          height: 4px;
          background: #2563eb;
          margin: 1rem auto 0;
          border-radius: 2px;
        }

        .achievements-grid {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          gap: 2rem;
          padding: 1rem;
        }

        .achievement-card {
          background: white;
          border-radius: 12px;
          box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
          overflow: hidden;
          transition: transform 0.3s, box-shadow 0.3s;
          height: 100%;
          display: flex;
          width: 60vh;
          flex-direction: column;
        }

        .achievement-card:hover {
          transform: translateY(-5px);
          box-shadow: 0 8px 15px rgba(0, 0, 0, 0.15);
        }

        .carousel-container {
          width: 100%;
        }

        .carousel-slide img {
          width: 100%;
          height: 220px;
          object-fit: cover;
        }

        .card-content {
          padding: 1.5rem;
          flex: 1;
          display: flex;
          flex-direction: column;
        }

        .card-header {
          margin-bottom: 1rem;
        }

        .student-name {
          font-size: 1.25rem;
          font-weight: bold;
          color: #333;
          margin: 0 0 0.5rem 0;
        }

        .date {
          display: flex;
          align-items: center;
          color: #666;
          font-size: 0.875rem;
          gap: 0.5rem;
        }

        .achievement-section {
          display: flex;
          align-items: flex-start;
          gap: 0.75rem;
          margin-bottom: 1rem;
          padding: 0.75rem;
          background: #f8f9fa;
          border-radius: 8px;
        }

        .achievement-title {
          font-size: 1.1rem;
          font-weight: 600;
          color: #2563eb;
          margin: 0;
        }

        .achievement-details {
          color: #666;
          line-height: 1.6;
          margin-bottom: 1.5rem;
          flex: 1;
        }

        .button-group {
          display: grid;
          grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
          gap: 1rem;
          margin-top: auto;
        }

        .button {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 0.5rem;
          padding: 0.75rem 1rem;
          border-radius: 8px;
          font-size: 0.875rem;
          font-weight: 500;
          cursor: pointer;
          transition: all 0.2s;
          width: 100%;
        }

        .button-outline {
          border: 2px solid #e5e7eb;
          background: transparent;
          color: #666;
        }

        .button-outline:hover {
          background: #f8f9fa;
          border-color: #2563eb;
          color: #2563eb;
        }

        .button-filled {
          background: #2563eb;
          color: white;
          border: none;
        }

        .button-filled:hover {
          background: #1d4ed8;
        }

        .icon {
          width: 18px;
          height: 18px;
        }

        .award-icon {
          color: #2563eb;
          width: 24px;
          height: 24px;
        }

        @media (max-width: 1200px) {
          .achievements-grid {
            grid-template-columns: 1fr;
            
          }
          .achievement-card {
            width: 40vh;
          }
        }

        @media (max-width: 768px) {
          .achievements-grid {
            grid-template-columns: 1fr;
            gap: 1.5rem;
          }
            
        .carousel .slide img {
        // height: fit;

        }
          .achievement-card {
            width: 40vh;
          }
          
          .achievements-container {
            width: 95%;
            // padding: 1rem 0;
          }
          
          .achievements-title {
            font-size: 2rem;
            margin-bottom: 2rem;
          }
          
          .card-content {
            padding: 1.25rem;
          }
        }

        .search-bar {
          width: 100%;
          padding: 0.75rem;
          margin-bottom: 1.5rem;
          border: 1px solid #e5e7eb;
          border-radius: 8px;
          font-size: 1rem;
        }

        .view-more-container {
          display: flex;
          justify-content: center;
          margin-top: 1.5rem;
        }

        .view-more-button {
          padding: 0.75rem 1.5rem;
          border: none;
          border-radius: 8px;
          background-color: #001C45;
          color: white;
          font-size: 1rem;
          cursor: pointer;
          transition: background-color 0.3s;
        }

        .view-more-button:hover {
          background-color: #001C45;
        }

        .loader {
          text-align: center;
          font-size: 1.5rem;
          color: #2563eb;
          margin: 2rem 0;
        }
      `}</style>
    </div>
  );
};

export default StudAchi;