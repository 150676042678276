import React, { useState, useEffect } from 'react';

const Block = (number, index) => {
  return (
    <div id='block' key={index}>
      {number}
    </div>
  );
}

const VisitCount = ({visitors}) => {
  const v = String(visitors).split('');
  return (
    <div className='visitorsContainer'>
      {v.map((x, index) => {
        return ( Block(x, index) )
      })}
    </div>
  )
}

function Footer(props){

	const [visitors, setVisitors] = useState(0);

	const getVisitors = async () => {
		// countapi.xyz stopped working so temporarily initialized data with 0
		// const { data } = await axios('https://api.countapi.xyz/update/spit.cse/spit/?amount=1');
		const data = 0;
		// eslint-disable-next-line
		if(data != null || data != 0) {
			setVisitors(parseInt(data?.value));
		}
	}

	useEffect(() => {
		getVisitors();
	}, []);

	return(
		<div id="footer">
	        <div id="footer-head">
				<div id="footer-head-logo">
					<img src="/assets/misc/spit-transparent-bg-logo.png" alt="" />
				</div>
				{/* <div id="footer-head-socials">
					<i className="fa-brands fa-linkedin"></i>
					<i className="fa-brands fa-instagram"></i>
					<i className="fa-brands fa-facebook"></i>
				</div> */}
	        </div>

			<div id='footer-main'>
				<div id="footer-info-left">
					<div id="footer-info-title">
						Department of Computer Science and Engineering
					</div>
					<div id="footer-college-title">
						Sardar Patel Institute of Technology
					</div>
					<div className="footer-info-content">
						Bhavan's Campus, Munshi Nagar, Andheri (West), Mumbai 400 058
					</div>
					<div className="footer-info-content">
						Email: hod.cse@spit.ac.in
					</div>
				</div>
				<div id="footer-info-right">
					<div>
						{ visitors > 0 &&
						<div className='visitors'>
							<div>
							<span className='visitorsHeading'> Total Visitors </span>
							<VisitCount visitors={visitors} /> 
							</div>
						</div>
						}
					</div>
				</div>
			</div>

			<div id="footer-copyright" style={{ textAlign: "center", color: 'white', fontFamily: 'Arial, sans-serif', fontSize: '17px', padding: '20px 0' }}>
			<p style={{ margin: 0 }}>Copyrights &copy; {new Date().getFullYear()} | Department of CSE @ S.P.I.T.</p>
			<div style={{ marginTop: '10px' }}>
				<p style={{ margin: 0 }}>Developed and Maintained by</p>
				<div style={{ display: 'flex', justifyContent: 'center', gap: '20px', fontWeight: 'bold' }}>
					<a href='https://www.linkedin.com/in/aayush-patel-2396b220a/' target='_blank' rel='noreferrer' style={{ color: 'white', textDecoration: 'none', transition: 'color 0.3s ease' }}>Rehan Ansari</a>
					<a href='https://www.linkedin.com/in/vnjhaveri/' target='_blank' rel='noreferrer' style={{ color: 'white', textDecoration: 'none', transition: 'color 0.3s ease' }}>Varun Jhaveri</a>
					<a href="https://www.linkedin.com/in/rayanjain/" target='_blank' rel='noreferrer' style={{ color: 'white', textDecoration: 'none', transition: 'color 0.3s ease' }}>Rayan Jain</a>
				</div>
			</div>
			<div style={{ marginTop: '10px' }}>
				<p style={{ margin: 0 }}>Originally Developed by</p>
				<div style={{ display: 'flex', justifyContent: 'center', gap: '20px', fontWeight: 'bold' }}>
					<a href='https://www.linkedin.com/in/aayush-patel-2396b220a/' target='_blank' rel='noreferrer' style={{ color: 'white', textDecoration: 'none', transition: 'color 0.3s ease' }}>Aayush Patel</a>
					<a href='https://www.linkedin.com/in/lalit-chandora-b37287176/' target='_blank' rel='noreferrer' style={{ color: 'white', textDecoration: 'none', transition: 'color 0.3s ease' }}>Lalit Chandora</a>
					<a href="https://www.linkedin.com/in/tripathi-ayush/" target='_blank' rel='noreferrer' style={{ color: 'white', textDecoration: 'none', transition: 'color 0.3s ease' }}>Ayush Tripathi</a>
					<a href="https://www.linkedin.com/in/maaz-malik-632756255/" target='_blank' rel='noreferrer' style={{ color: 'white', textDecoration: 'none', transition: 'color 0.3s ease' }}>Maaz Malik</a>
				</div>
			</div>
		</div>
	</div>   
	)
}

export default Footer;