import React from 'react';
import styles from '../../styles/events/workshops.module.css';
import axios from 'axios';
import { useState, useEffect } from 'react';

function Workshops(props){

    const [data, setWorkshopData] = useState({"workshops": ""});
    const baseUrl = "https://cse.spit.ac.in/api"

    const fetchData = async () => {
        try {
          const response = await axios.get(`${baseUrl}/events/workshops`);
          setWorkshopData({"workshops": response.data});
        } catch (error) {
        //   console.error("Error fetching data:", error);
        }
      };

      useEffect(() => {
        fetchData()
    }, []);

    if(data["workshops"] != ""){
        // console.log(data["workshops"]);
    }

    return(
        <>
            <div className={styles.topBanner}>
                <span className={styles.bannerTitle}> Events / Workshops </span>
            </div>
            <div className={styles.outerWrapper}>
                <h2 className={styles.title}> Workshops </h2>
                {
                    data["workshops"] != "" && data["workshops"].map((item, index) => (
                        <div className={styles.wrapper}> 
                            <div className={styles.container}>
                                
                                        <div className={styles.card} key={index}>
                                            <img src={`data:image/jpeg;base64, ${item.banner}`} alt="" className={styles.banner} key={index} />
                                            <div className={styles.data}  key={index}>
                                                <span className={styles.eventTitle}> <strong> {item.title} </strong> </span>
                                                <p>
                                                    {item.details}
                                                </p>
                                                {
                                                    item.speaker != "" && (  
                                                        <div className={styles.speaker} key={index}>
                                                            <span className={styles.speakerHeading}> Speaker(s): </span>
                                                            {
                                                        item.speaker.split(', ').map((speaker, index) => (
                                                            <ul className={styles.speakerRow} key={index}>
                                                                <li className={styles.speakerName}>
                                                                    {speaker}
                                                                </li>
                                                            </ul>
                                                        ))}
                                                        </div>
                                                    )
                                                }
                                                {
                                                    item.start_date && (
                                                        <div className={styles.eventDate}>
                                                            <span className={styles.dateHeading}> Start Date:  </span>
                                                            <span className={styles.dateValue}> {item.start_date} </span>
                                                        </div>
                                                    )
                                                }
                                                {
                                                    item.end_date && (
                                                        <div className={styles.eventDate}>
                                                            <span className={styles.dateHeading}> End Date:  </span>
                                                            <span className={styles.dateValue}> {item.end_date} </span>
                                                        </div>
                                                    )
                                                }
                                                {/* {
                                                    item.time && (
                                                        <div className={styles.eventTime}>
                                                            <span className={styles.timeHeading}> Time:  </span>
                                                            <span className={styles.timeValue}> {item.time} </span>
                                                        </div>
                                                    )
                                                } */}
                                                <a href={baseUrl+'/api/files/'+item.document.replace('/resources/resources/', '')} target="_blank" rel="noreferrer" className={styles.document}> View Details </a>
                                            </div>
                                        </div>
                                    
                            </div>
                            
                        </div>
                        ))
                    }
            </div>
        </>
    )
}

export default Workshops;